import React from "react";
import "./form-loading.css";
const Formloading = () => {
  return (
    <div className="center form-loading">
      <div className="center">
        <div className="center">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Formloading;
